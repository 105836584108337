import Button from "@mui/material/Button";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import * as React from "react";
import remoteGATTServerManager from "./RemoteGATTServerManager";

export const ScanDevicesButton = ({
  setGATTServers
}: {
  setGATTServers: (servers: BluetoothRemoteGATTServer[]) => void;
}) => {
  const scanDevices = async () => {
    try {
      await remoteGATTServerManager.scanDevices();
      const servers = Array.from(remoteGATTServerManager.remoteGATTServers);
      setGATTServers(servers);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Button
      variant="contained"
      endIcon={<AutorenewIcon />}
      onClick={() => scanDevices()}
    >
      Etsi sensoreita
    </Button>
  );
};
