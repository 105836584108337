import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { Sensor } from "../../store/apis/sensorsApi";
import RemoteGATTServerManager from "../utils/RemoteGATTServerManager";
import { useEffect, useState } from "react";

export const SensorRow = ({
  server,
  savedSensors,
  addSensor
}: {
  server: BluetoothRemoteGATTServer;
  savedSensors: Sensor[];
  addSensor: (name: string, sensorId: string) => void;
}) => {
  const found = savedSensors.some(
    sensor => sensor.sensorId === (server.armId || server.device.id)
  );
  const { name, id } = server.device;

  return (
    <TableRow>
      <TableCell>{server.armId || id}</TableCell>
      <TableCell align="right">
        {!found && (
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={() => addSensor(name || "", server.armId || id)}
          >
            Tallenna
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
