import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Customer,
  useLoadAllCustomersQuery,
  Animal
} from "../../store/apis/customersApi";

export const SelectCustomer = ({
  open,
  handleClose,
  setCustomer,
  customer,
  handleCancel,
  animal,
  setAnimal
}: {
  open: boolean;
  handleClose: (event: React.SyntheticEvent<unknown>, reason?: string) => void;
  setCustomer: (customer: Customer) => void;
  customer?: Customer;
  handleCancel: () => void;
  animal?: Animal;
  setAnimal: (animal: Animal) => void;
}) => {
  const { data } = useLoadAllCustomersQuery(null);

  const handleChangeCustomer = (event: any) => {
    const foundCustomer =
      data && data.filter(c => c.id === parseInt(event.target.value));
    if (foundCustomer && foundCustomer.length) {
      setCustomer(foundCustomer[0]);
    }
  };
  const handleChangeAnimal = (event: any) => {
    const foundAnimal =
      customer &&
      customer.animals &&
      customer.animals.filter(a => a.id === parseInt(event.target.value));
    if (foundAnimal && foundAnimal.length) {
      setAnimal(foundAnimal[0]);
    }
  };

  const renderSelectAnimal = () => {
    if (!customer) return <div />;
    else
      return (
        <>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="elain">Eläin</InputLabel>
            <Select
              native
              value={animal ? animal.id : ""}
              onChange={handleChangeAnimal}
              input={<OutlinedInput label="Eläin" id="elain" />}
            >
              <option aria-label="None" value="" />
              {customer.animals &&
                customer.animals.map(animal => (
                  <option key={animal.id} value={animal.id}>
                    {animal.name}
                  </option>
                ))}
            </Select>
          </FormControl>
        </>
      );
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleCancel}>
      <DialogTitle>Valitse asiakas</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="asiakas">Asiakas</InputLabel>
            <Select
              native
              value={customer ? customer.id : ""}
              onChange={handleChangeCustomer}
              input={<OutlinedInput label="Asiakas" id="asiakas" />}
            >
              <option aria-label="None" value="" />
              {data &&
                data.map(customer => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          {renderSelectAnimal()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Peru</Button>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
