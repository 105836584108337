import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../dashboard/Title";

import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {
  useAddCustomerMutation,
  useDeleteCustomerMutation,
  useLoadAllCustomersQuery,
  useModifyCustomerMutation
} from "../../store";
import { AddCustomerForm } from "./AddCustomerForm";
import { CustomerRow } from "./CustomerRow";
import { Customer } from "../../store/apis/customersApi";
import { useEffect, useState } from "react";
import { SuccessErrorToast } from "../utils/SuccessErrorToast";

export default function Users() {
  const [addCustomerOpen, setAddCustomerOpen] = React.useState(false);

  const { data } = useLoadAllCustomersQuery(null);
  const [addCustomer, addCustomerResult] = useAddCustomerMutation();
  const [deleteCustomer, deleteCustomerResult] = useDeleteCustomerMutation();
  const [modifyCustomer, modifyCustomerResult] = useModifyCustomerMutation();

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const [successDeleteOpen, setSuccessDeleteOpen] = useState(false);
  const [errorDeleteOpen, setErrorDeleteOpen] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  useEffect(() => {
    if (addCustomerResult.isSuccess) {
      setSuccessOpen(true);
    }
    if (addCustomerResult.isError) {
      setErrorOpen(true);
    }
  }, [addCustomerResult]);

  useEffect(() => {
    if (deleteCustomerResult.isSuccess) {
      setSuccessDeleteOpen(true);
    }
    if (deleteCustomerResult.isError) {
      setErrorDeleteOpen(true);
    }
  }, [deleteCustomerResult]);

  useEffect(() => {
    if (selectedCustomer) {
      setAddCustomerOpen(true);
    }
  }, [selectedCustomer]);

  const handleAddCustomerOpen = () => {
    setAddCustomerOpen(true);
  };

  const handleAddCustomerClose = () => {
    setAddCustomerOpen(false);
    setSelectedCustomer(undefined);
  };

  const handleAddOrModifyCustomer = (newCustomer: Customer) => {
    const { name, id } = newCustomer;
    console.log(newCustomer);
    if (id) {
      modifyCustomer(newCustomer);
    } else {
      addCustomer({ name });
    }
  };

  return (
    <React.Fragment>
      <SuccessErrorToast
        successOpen={successOpen}
        errorOpen={errorOpen}
        successMessage="Asiakas lisätty onnistuneesti!"
        errormessage="Asiakkaan lisääminen epäonnistui!"
        setSuccessOpen={setSuccessOpen}
        setErrorOpen={setErrorOpen}
      />
      <SuccessErrorToast
        successOpen={successDeleteOpen}
        errorOpen={errorDeleteOpen}
        successMessage="Asiakas poistettu onnistuneesti!"
        errormessage="Asiakkaan poistaminen epäonnistui!"
        setSuccessOpen={setSuccessDeleteOpen}
        setErrorOpen={setErrorDeleteOpen}
      />
      <AddCustomerForm
        open={addCustomerOpen}
        handleAddClose={handleAddCustomerClose}
        handleAddCustomer={handleAddOrModifyCustomer}
        selectedCustomer={selectedCustomer}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Asiakkaat</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map(customer => (
                  <CustomerRow
                    customer={customer}
                    key={customer.id}
                    deleteCustomer={deleteCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                  />
                ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            endIcon={<Add />}
            onClick={handleAddCustomerOpen}
          >
            Lisää
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
