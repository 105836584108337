import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./apis/authApi";
import { usersApi } from "./apis/usersApi";
import { authSlice } from "./features/authSlice";
import { customersApi } from "./apis/customersApi";
import { sensorsApi } from "./apis/sensorsApi";
import { measurementresultsApi } from "./apis/measurementresultsApi";
import { singleresultApi } from "./apis/singleResultApi";

import { connectedDevicesSlice } from "./features/connectedDeviceSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    connectedDevices: connectedDevicesSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [sensorsApi.reducerPath]: sensorsApi.reducer,
    [measurementresultsApi.reducerPath]: measurementresultsApi.reducer,
    [singleresultApi.reducerPath]: singleresultApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(customersApi.middleware)
      .concat(sensorsApi.middleware)
      .concat(measurementresultsApi.middleware)
      .concat(singleresultApi.middleware),
  devTools: true
});

setupListeners(store.dispatch);

export { useTryLoginMutation } from "./apis/authApi";
export { logout, selectToken, selectCurrentUser } from "./features/authSlice";
export {
  add,
  remove,
  selectConnectedDevices
} from "./features/connectedDeviceSlice";

export {
  useLoadAllUsersQuery,
  useDeleteUserMutation,
  useAddUserMutation
} from "./apis/usersApi";

export {
  useLoadAllCustomersQuery,
  useDeleteCustomerMutation,
  useAddCustomerMutation,
  useAddAnimalMutation,
  useDeleteAnimalMutation,
  useModifyCustomerMutation,
  useModifyAnimalMutation
} from "./apis/customersApi";

export {
  useAddSensorMutation,
  useDeleteSensorMutation,
  useLoadAllSensorsQuery,
  useModifySensorMutation
} from "./apis/sensorsApi";

export {
  useLoadMeasurementResultsQuery,
  useAddResultMutation
} from "./apis/measurementresultsApi";

export { useLoadMeasurementResultQuery } from "./apis/singleResultApi";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
