import React from "react";
import Login from "./components/login/Login";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateOutlet } from "./components/PrivateOutlet";
import Users from "./components/users/Users";
import Customers from "./components/customers/Customers";
import CustomerForm from "./components/customers/CustomerForm";
import Sensors from "./components/sensors/Sensors";
import MeasurementResults from "./components/measurement-results/MeasurementResults";
import Measurement from "./components/measurement/Measurement";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="/users" element={<Users />} />
          <Route path="/" index element={<Customers />} />
          <Route path="/sensors" element={<Sensors />} />
          <Route path="/measurementResults" element={<MeasurementResults />} />
          <Route path="/measurement" element={<Measurement />} />
          <Route path="/customer/:customerId" element={<CustomerForm />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
