import { useAuth } from "../hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";

export function PrivateOutlet() {
  const auth = useAuth();
  const location = useLocation();

  return auth.user.email ? (
    <Dashboard />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
