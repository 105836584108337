import Title from "../dashboard/Title";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { SavedSensorRow } from "./SavedSensorRow";
import * as React from "react";
import { Sensor, useDeleteSensorMutation } from "../../store/apis/sensorsApi";

export const SavedSensors = ({
  savedSensors,
  GATTServers
}: {
  savedSensors: Sensor[];
  GATTServers: BluetoothRemoteGATTServer[];
}) => {
  const [deleteSensor, deleteSensorResult] = useDeleteSensorMutation();

  const remove = (id: number) => {
    deleteSensor(id);
  };

  const renderSensors = () => {
    if (!savedSensors.length) {
      return <div>Ei tallennettuja sensoreita.</div>;
    } else {
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {savedSensors &&
              savedSensors.map(sensor => (
                <SavedSensorRow
                  key={sensor.id}
                  savedSensor={sensor}
                  removeSensor={remove}
                  online={GATTServers.map(server => [
                    server.device.id,
                    server.armId
                  ])
                    .flat()
                    .includes(sensor.sensorId)}
                />
              ))}
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <>
      <Title>Sensorit</Title>
      {renderSensors()}
    </>
  );
};
