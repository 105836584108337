import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Title from "../dashboard/Title";

import Grid from "@mui/material/Grid";
import {
  store,
  useLoadMeasurementResultsQuery
} from "../../store";
import { useState } from "react";
import { MeasurementResultRow } from "./MeasurementResultRow";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {singleresultApi} from "../../store/apis/singleResultApi";
import {RawData} from "../utils/RemoteGATTServerManager";
import {generateCSV} from "./generateCSV";


export default function MeasurementResults() {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const { data } = useLoadMeasurementResultsQuery({ pageSize, page });

  const handleDownload = async (id: number) => {
    const promise = store.dispatch(singleresultApi.endpoints?.loadMeasurementResult.initiate({id}))
    const {data} = await promise;
    if(data){
      generateCSV(data);
    }
  };
  const renderRows = () => {
    if (!data) return <></>;
    else {
      return data.map(result => (
        <MeasurementResultRow
          result={result}
          key={result.id}
          handleDownload={handleDownload}
        />
      ));
    }
  };

  const renderPrev = () => {
    if (page === 1) return <></>;
    else {
      return (
        <NavigateBeforeIcon
          className={"pointer"}
          onClick={() => {
            setPage(page - 1);
          }}
        />
      );
    }
  };

  const renderNext = () => {
    if (data && data.length < pageSize) return <></>;
    else {
      return (
        <NavigateNextIcon
          className={"pointer"}
          onClick={() => {
            setPage(page + 1);
          }}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Mittaustulokset</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Päivä</TableCell>
                <TableCell>Asiakas</TableCell>
                <TableCell>Eläin</TableCell>
                <TableCell>Laji</TableCell>
                <TableCell>Rotu</TableCell>
                <TableCell>Syntymäaika</TableCell>
                <TableCell>Askellus</TableCell>
                <TableCell>Jalka</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-start">
            {renderPrev()}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            {renderNext()}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
