import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Sensor, useModifySensorMutation } from "../../store/apis/sensorsApi";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Button from "@mui/material/Button";
import { RenameSensor } from "./RenameSensor";
import { useState } from "react";

export const SavedSensorRow = ({
  savedSensor,
  removeSensor,
  online
}: {
  savedSensor: Sensor;
  removeSensor: (id: number) => void;
  online: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [modify, modifyResult] = useModifySensorMutation();

  const handleRename = (name: string) => {
    const modifiedSensor = { ...savedSensor, humanName: name };
    modify(modifiedSensor);
  };

  const renderConnected = () => {
    if (online)
      return (
        <Button
          style={{ backgroundColor: "transparent", cursor: "default" }}
          size={"small"}
          color={"success"}
        >
          Yhdistetty
        </Button>
      );
    else return <></>;
  };

  const buttonText = savedSensor.humanName ? "Nimeä uudelleen" : "Anna nimi";

  return (
    <TableRow>
      <RenameSensor
        open={open}
        handleClose={() => setOpen(false)}
        handleRename={handleRename}
        id={savedSensor.sensorId}
      />
      <TableCell>{savedSensor.humanName}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          size={"small"}
          onClick={() => {
            setOpen(true);
          }}
        >
          {buttonText}
        </Button>
      </TableCell>
      <TableCell>{savedSensor.sensorId}</TableCell>
      <TableCell> {renderConnected()}</TableCell>
      <TableCell align="right">
        <Button
          style={{ backgroundColor: "transparent" }}
          startIcon={
            <DeleteForeverOutlinedIcon
              onClick={() => removeSensor(savedSensor.id || 0)}
              style={{ cursor: "pointer", width: "1.5em", height: "1.5em" }}
            />
          }
        ></Button>
      </TableCell>
    </TableRow>
  );
};
