import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Animal } from "../../store/apis/customersApi";
import dayjs from "dayjs";

export const AddAnimalForm = ({
  open,
  handleAddClose,
  handleAddAnimal,
  selectedAnimal
}: {
  open: boolean;
  handleAddClose: () => void;
  handleAddAnimal: (
    name: string,
    species: string,
    race: string,
    DoB: Date
  ) => void;
  selectedAnimal: Animal | undefined;
}) => {
  const initErrors = {
    name: "",
    race: "",
    species: "",
    customer: 0,
    DoB: ""
  };

  const [name, setName] = useState("");
  const [species, setSpecies] = useState("Koira");
  const [race, setRace] = useState("");
  const [DoB, setDoB] = useState(dayjs);

  const [errors, setErrors] = useState<Animal>(initErrors);

  useEffect(() => {
    if (selectedAnimal) {
      setName(selectedAnimal.name);
      setSpecies(selectedAnimal.species);
      setRace(selectedAnimal.race || "");
      setDoB(dayjs(selectedAnimal.DoB));
    }
  }, [selectedAnimal]);

  const close = () => {
    setErrors(initErrors);
    handleAddClose();
  };
  const addAnimal = () => {
    const err: Animal = {
      name: "",
      species: "",
      race: "",
      customer: 0,
      DoB: ""
    };
    let errors = false;
    if (name.length < 1) {
      errors = true;
      err.name = "Anna nimi";
    }
    if (!errors) {
      handleAddAnimal(name, species, race, DoB.toDate());
      close();
    } else {
      setErrors(err);
    }
  };

  useEffect(() => {
    if (!open) {
      setName("");
      setDoB(dayjs);
      setRace("");
    }
  }, [open]);

  const handleSpeciesChange = (event: SelectChangeEvent) => {
    setSpecies(event.target.value as string);
  };
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        {selectedAnimal ? "Muokkaa eläimen tietoja" : "Lisää asiakkaan eläin"}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errors.name)}
          helperText={errors.name}
          autoFocus
          margin="dense"
          id="name"
          label="Nimi"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setName(event.target.value)}
          value={name}
        />
        <Select value={species} onChange={handleSpeciesChange}>
          <MenuItem value="Koira">Koira</MenuItem>
          <MenuItem value="Hevonen">Hevonen</MenuItem>
        </Select>
        <TextField
          error={Boolean(errors.race)}
          helperText={errors.race}
          autoFocus
          margin="dense"
          id="race"
          label="Rotu"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setRace(event.target.value)}
          value={race}
        />
        <DatePicker
          value={DoB}
          onChange={newValue => setDoB(newValue || dayjs)}
          format={"DD.MM.YYYY"}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={addAnimal}>
          {selectedAnimal ? "Muokkaa" : "Lisää"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
