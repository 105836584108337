import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

export function SuccessErrorToast({
  successOpen,
  errorOpen,
  successMessage,
  errormessage,
  setSuccessOpen,
  setErrorOpen
}: {
  successOpen: boolean;
  errorOpen: boolean;
  successMessage: string;
  errormessage: string;
  setSuccessOpen: (val: boolean) => void;
  setErrorOpen: (val: boolean) => void;
}) {
  return (
    <>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errormessage}
        </Alert>
      </Snackbar>
    </>
  );
}
