import * as React from "react";
import { ConnectedDevice } from "../../store/features/connectedDeviceSlice";
import CircularProgress, {
  CircularProgressProps
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import remoteGATTServerManager from "../utils/RemoteGATTServerManager";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const DownloadBar = ({ sensor }: { sensor: ConnectedDevice }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const downloadInterval = setInterval(() => {
      setProgress(remoteGATTServerManager.getDownloadedLength(sensor.armId!));
    }, 500);
    return () => clearInterval(downloadInterval);
  }, [sensor.armId]);
  if (sensor.measurementLength) {
    return (
      <CircularProgressWithLabel
        value={(progress / sensor.measurementLength) * 100}
      />
    );
  }
  return <div />;
};
