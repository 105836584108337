import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";
import { API_BASE_URL } from "../../constants";
import { Animal, Customer } from "./customersApi";

export interface MeasurementResult {
  id: number;
  data: string;
  animal: Animal;
  customer: Customer;
  date: string;
  movement: string;
  leg?: string;
}

export interface NewMeasurementResult {
  data: string;
  movement: string;
  leg?: string;
  customerId: number;
  animalId: number;
}

export interface FetchMeasurementResults {
  pageSize: number;
  page: number;
}

const measurementresultsApi = createApi({
  reducerPath: "measurementresults",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/measurementresults`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
  }),
  tagTypes: ["loadResults"],
  endpoints(builder) {
    return {
      loadMeasurementResults: builder.query<
        MeasurementResult[],
        FetchMeasurementResults
      >({
        providesTags: users => ["loadResults"],
        query: ({ pageSize, page }) => {
          return {
            url: `/${pageSize}/${page}`,
            method: "GET"
          };
        }
      }),
      /*  deleteSensor: builder.mutation<MeasurementResult, number>({
        invalidatesTags: ["loadSensors"],
        query: id => {
          return {
            url: `/${id}`,
            method: "DELETE"
          };
        }
      }), */
      addResult: builder.mutation<MeasurementResult, NewMeasurementResult>({
        invalidatesTags: ["loadResults"],
        query: newSensor => {
          return {
            url: "/",
            method: "POST",
            body: {
              ...newSensor
            }
          };
        }
      }) /*
      modifySensor: builder.mutation<MeasurementResult, MeasurementResult>({
        invalidatesTags: ["loadSensors"],
        query: sensor => {
          return {
            url: `/${sensor.id}`,
            method: "PATCH",
            body: {
              humanName: sensor.humanName
            }
          };
        }
      }) */
    };
  }
});

export const {
  useLoadMeasurementResultsQuery,
  useAddResultMutation
} = measurementresultsApi;
export { measurementresultsApi };
