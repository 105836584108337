import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
//import RemoteGATTServerManager from "../utils/RemoteGATTServerManager";

export const RenameSensor = ({
  open,
  handleClose,
  handleRename,
  id
}: {
  open: boolean;
  handleClose: () => void;
  handleRename: (newName: string) => void;
  id: string;
}) => {
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");

  const close = () => {
    handleClose();
  };
  const renameSensor = () => {
    if (name.length < 1) {
      setErrorText("Anna nimi");
    } else {
      handleRename(name);

      //      const server = RemoteGATTServerManager.getGATTServer(id);
      //      RemoteGATTServerManager.renameSensor(name, server);
      close();
    }
  };

  useEffect(() => {
    if (!open) {
      setName("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Nimeä sensori</DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errorText)}
          helperText={errorText}
          autoFocus
          margin="dense"
          id="name"
          label="Nimi"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setName(event.target.value)}
          value={name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={renameSensor}>Muuta nimi</Button>
      </DialogActions>
    </Dialog>
  );
};
