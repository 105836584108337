import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";
import { API_BASE_URL } from "../../constants";
import { MeasurementResult } from "./measurementresultsApi";

export interface FetchMeasurementResult {
  id: number;
}

const singleresultApi = createApi({
  reducerPath: "singleresult",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/measurementresults`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
  }),
  endpoints(builder) {
    return {
      loadMeasurementResult: builder.query<
        MeasurementResult,
        FetchMeasurementResult
      >({
        query: ({ id }) => {
          return {
            url: `single/${id}`,
            method: "GET"
          };
        }
      })
    };
  }
});

export const { useLoadMeasurementResultQuery } = singleresultApi;
export { singleresultApi };
