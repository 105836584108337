import * as React from "react";

import {
  Animal,
  Customer,
  useAddAnimalMutation, useDeleteAnimalMutation, useLoadAllCustomersQuery, useModifyAnimalMutation,
} from "../../store/apis/customersApi";
import Button from "@mui/material/Button";
import { Add, ArrowBack } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Title from "../dashboard/Title";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { AddAnimalForm } from "./AddAnimalForm";
import {AnimalRow} from "./AnimalRow";
import {SuccessErrorToast} from "../utils/SuccessErrorToast";

export default function CustomerForm() {
  const navigate = useNavigate();

  const [customer, setCustomer] = useState<Customer>();
  const { customerId } = useParams();

  const [addAnimalOpen, setAddAnimalOpen] = useState(false);

  const [addAnimal, addAnimalResult] = useAddAnimalMutation();
  const [deleteAnimal, deleteAnimalResult] = useDeleteAnimalMutation();
  const [modifyAnimal, modifyAnimalResult] = useModifyAnimalMutation();

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const [successDeleteOpen, setSuccessDeleteOpen] = useState(false);
  const [errorDeleteOpen, setErrorDeleteOpen] = useState(false);
  const [selectedAnimal, setSelectedAnimal] = useState<Animal>();

  const {data} = useLoadAllCustomersQuery(null);

  useEffect(() => {
    if (addAnimalResult.isSuccess) {
      setSuccessOpen(true);
    }
    if (addAnimalResult.isError) {
      setErrorOpen(true);
    }
  }, [addAnimalResult]);

  useEffect(() => {
    if (deleteAnimalResult.isSuccess) {
      setSuccessDeleteOpen(true);
    }
    if (deleteAnimalResult.isError) {
      setErrorDeleteOpen(true);
    }
  }, [deleteAnimalResult]);

  useEffect(() => {
    if (customerId !== undefined && data) {
      const id = parseInt(customerId);
      const c = data.filter(cust => cust.id === id);
      if (c.length) setCustomer(c[0]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedAnimal) {
      setAddAnimalOpen(true);
    }
  }, [selectedAnimal]);

  const handleAddAnimalClose = () => {
    setAddAnimalOpen(false);
    setSelectedAnimal(undefined);
  };

  const handleAddOrModifyAnimal = (name: string, species: string, race = "", DoB: Date) => {
    if (customer && customer.id !== undefined) {
      if(!selectedAnimal){
        addAnimal({ name, species, race, customer: customer.id, DoB: DoB.toString() });
      } else {
        modifyAnimal({...selectedAnimal, name, species, race, DoB: DoB.toString() })
      }
    }
  };






  return (
    <React.Fragment>
      <SuccessErrorToast
          successOpen={successOpen}
          errorOpen={errorOpen}
          successMessage="Eläin lisätty onnistuneesti!"
          errormessage="Eläimen lisääminen epäonnistui!"
          setSuccessOpen={setSuccessOpen}
          setErrorOpen={setErrorOpen}
      />
      <SuccessErrorToast
          successOpen={successDeleteOpen}
          errorOpen={errorDeleteOpen}
          successMessage="Eläin poistettu onnistuneesti!"
          errormessage="Eläimen poistaminen epäonnistui!"
          setSuccessOpen={setSuccessDeleteOpen}
          setErrorOpen={setErrorDeleteOpen}
      />
      <AddAnimalForm
        open={addAnimalOpen}
        handleAddClose={handleAddAnimalClose}
        handleAddAnimal={handleAddOrModifyAnimal}
        selectedAnimal={selectedAnimal}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>{customer && customer.name} / Eläimet</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Laji</TableCell>
                <TableCell>Rotu</TableCell>
                <TableCell>Syntymäaika</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{customer?.animals && customer?.animals.map(animal => <AnimalRow key={animal.id} animal={animal} handleDelete={deleteAnimal} setSelectedAnimal={setSelectedAnimal}/> )}</TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid container padding={1} spacing={2} justifyContent="space-between">
        <Grid item xs={3}>
          <Button
            onClick={() => setAddAnimalOpen(true)}
            variant="contained"
            endIcon={<Add />}
          >
            Lisää eläin
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Grid container justifyContent="flex-end">
            <Grid item alignItems="flex-end">
              <Button
                variant="contained"
                onClick={() => navigate("/")}
                startIcon={<ArrowBack />}
              >
                Takaisin
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
