import { MeasurementResult } from "../../store/apis/measurementresultsApi";
import { RawData } from "../utils/RemoteGATTServerManager";
import * as fs from "fs";

/*
  Header
  omistaja;eläin;päivä;askellus;jalka;
  Data
  sensori;acc_x,acc_y,acc_z,rot_x,rot_y,rot_z,millisekunnit;
*/
export const generateCSV = (result: MeasurementResult) => {
  const dataObj = JSON.parse(result.data) as RawData[];
  const header = generateHeader(result);
  let data = "";
  const sensors = getSensors(dataObj);
  sensors.forEach(sensor => {
    const dataToHandle = dataObj.filter(result => result.id === sensor);
    dataToHandle.forEach(row => {
      data += row.id + ";";
      data += row.value + ";\n";
    });
  });
  generateFile(header, data, result);
};

const generateHeader = (result: MeasurementResult): string => {
  let headerText = "";
  headerText += result.customer.name + ";";
  headerText += result.animal.name + ";";
  headerText += new Date(result.date).toLocaleDateString() + ";";
  headerText += result.movement + ";";
  headerText += result.leg + ";\n";
  return headerText;
};

const getSensors = (data: RawData[]) => {
  const allSensors = data.map(data => data.id);
  return new Set(allSensors);
};

const generateFile = (
  header: string,
  data: string,
  result: MeasurementResult
) => {
  const fileName =
    result.customer.name +
    "_" +
    result.animal.name +
    "_" +
    new Date(result.date).toLocaleDateString().replaceAll(".", "-") +
    ".csv";

  const file = new Blob([header + data], { type: "text/plain;charset=utf-8" });
  const element = document.createElement("a");
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
};
