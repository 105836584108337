import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../constants";

export interface Credentials {
  email: string;
  password: string;
}

export interface LoggedInUser {
  id: number;
  email: string;
  name: string;
  token: string;
}

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/auth`
  }),
  endpoints(builder) {
    return {
      tryLogin: builder.mutation<LoggedInUser, Credentials>({
        query: data => {
          return {
            url: "/signin",
            body: {
              email: data.email,
              password: data.password
            },
            method: "POST"
          };
        }
      })
    };
  }
});

export const { useTryLoginMutation } = authApi;
export { authApi };
