import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { LoggedInUser } from "../apis/authApi";
import { authApi } from "../apis/authApi";

export interface AuthState {}

const initialState: LoggedInUser = {
  id: 0,
  name: "",
  email: "",
  token: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState ,
  reducers: {
        logout() {
          return initialState;}
  },
    extraReducers: builder => {
      builder.addMatcher(authApi.endpoints?.tryLogin.matchFulfilled, (state, {payload}) => {
        return {...payload}
      });
    }

});

export const selectToken = (state: RootState) => state.auth.email;
export default authSlice.reducer;

export const {logout} = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth