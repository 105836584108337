import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { MeasurementResult } from "../../store/apis/measurementresultsApi";
import DownloadIcon from "@mui/icons-material/Download";
export const MeasurementResultRow = ({
  result,
  handleDownload
}: {
  result: MeasurementResult;
  handleDownload: (id: number) => void;
}) => {
  return (
    <TableRow>
      <TableCell>{new Date(result.date).toLocaleDateString()}</TableCell>
      <TableCell>{result.customer.name}</TableCell>
      <TableCell>{result.animal.name}</TableCell>
      <TableCell>{result.animal.species}</TableCell>
      <TableCell>{result.animal.race}</TableCell>
      <TableCell>{new Date(result.animal.DoB).toLocaleDateString()}</TableCell>
      <TableCell>{result.movement}</TableCell>
      <TableCell>{result.leg}</TableCell>
      <TableCell>
        <DownloadIcon
          className={"pointer"}
          onClick={() => handleDownload(result.id)}
        />
      </TableCell>
    </TableRow>
  );
};
