import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { SensorRow } from "./SensorRow";
import * as React from "react";
import { useAddSensorMutation } from "../../store";
import { Sensor } from "../../store/apis/sensorsApi";

export const NewSensors = ({
  GATTServers,
  savedSensors
}: {
  GATTServers: BluetoothRemoteGATTServer[];
  savedSensors: Sensor[];
}) => {
  const [addSensor, addSensorResult] = useAddSensorMutation();
  const add = (name: string, sensorId: string) => {
    addSensor({ name, sensorId });
  };

  const currentIds = savedSensors.map(sensor => sensor.sensorId);
  const newSensors = GATTServers.filter(server => {
    return !currentIds.some(id => id === (server.armId || server.device.id));
  });

  if (!newSensors.length) return <div />;
  else
    return (
      <>
        <Divider textAlign="left">
          <Typography variant="h6">Uudet sensorit</Typography>
        </Divider>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newSensors &&
              newSensors.map(server => (
                <SensorRow
                  key={server.device.id}
                  server={server}
                  savedSensors={savedSensors || []}
                  addSensor={add}
                />
              ))}
          </TableBody>
        </Table>
      </>
    );
};
