import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export enum sensorState {
  UNKNOWN,
  READY,
  MEASURING,
  SENDINGDATA
}

export const sensorStateString = [
  "Tuntematon",
  "Valmis",
  "Mittaus aloitettu",
  "Ladataan"
];

export interface ConnectedDevice {
  id: string;
  armId?: string;
  name?: string;
  connectionLost?: boolean;
  humanName?: string;
  sensorState?: sensorState;
  measurementLength?: number;
}

const initialState: ConnectedDevice[] = [];

export const connectedDevicesSlice = createSlice({
  name: "connectedDevices",
  initialState,
  reducers: {
    add(state, action: PayloadAction<ConnectedDevice>) {
      return [
        ...state.filter(device => device.id !== action.payload.id),
        { ...action.payload, connectionLost: false }
      ];
    },
    remove(state, action: PayloadAction<ConnectedDevice>) {
      return [
        ...state.filter(device => device.id !== action.payload.id),
        { ...action.payload, connectionLost: true }
      ];
    },
    setMeasurementLength(state, action: PayloadAction<ConnectedDevice>) {
      const original = state.find(dev => dev.id === action.payload.id);
      if (original) {
        return [
          ...state.filter(device => device.id !== action.payload.id),
          {
            ...original,
            measurementLength: action.payload.measurementLength
          }
        ];
      }
    }
  }
});

export default connectedDevicesSlice.reducer;

export const {
  add,
  remove,
  setMeasurementLength
} = connectedDevicesSlice.actions;
export const selectConnectedDevices = (state: RootState) =>
  state.connectedDevices;
