import { useDeleteUserMutation, User } from "../../store/apis/usersApi";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { selectCurrentUser } from "../../store";

import ConfirmationDialog from "../utils/ConfirmationAlert";
import * as React from "react";
export const UserRow = ({
  user,
  deleteUser
}: {
  user: User;
  deleteUser: (user: User) => void;
}) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    deleteUser(user);
  };

  if (!user) {
    return <TableRow></TableRow>;
  } else
    return (
      <>
        <ConfirmationDialog
          title={"Varoitus"}
          body={`Haluatko varmasti poistaa käyttäjän ${user.name}?`}
          cb={handleDelete}
          okButtonText={"Poista"}
          handleClose={() => setOpen(false)}
          open={open}
        />
        <TableRow key={user.id} hover={true}>
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            {currentUser.id === user.id ? (
              <div />
            ) : (
              <DeleteForeverOutlinedIcon
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
              />
            )}
          </TableCell>
        </TableRow>
      </>
    );
};
