import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../dashboard/Title";

import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useLoadAllUsersQuery
} from "../../store";
import { AddUserForm } from "./AddUserForm";
import { UserRow } from "./UserRow";
import { NewUser } from "../../store/apis/usersApi";
import { useEffect, useState } from "react";
import { SuccessErrorToast } from "../utils/SuccessErrorToast";

export default function Users() {
  const [open, setOpen] = React.useState(false);
  const { data } = useLoadAllUsersQuery(null);
  const [addUser, addUserResult] = useAddUserMutation();
  const [deleteUser, deleteResult] = useDeleteUserMutation();

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const [successDeleteOpen, setSuccessDeleteOpen] = useState(false);
  const [errorDeleteOpen, setErrorDeleteOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (addUserResult.isSuccess) {
      setSuccessOpen(true);
    }
    if (addUserResult.isError) {
      setErrorOpen(true);
    }
  }, [addUserResult]);

  useEffect(() => {
    if (deleteResult.isSuccess) {
      setSuccessDeleteOpen(true);
    }
    if (deleteResult.isError) {
      setErrorDeleteOpen(true);
    }
  }, [deleteResult]);

  const handleAddClose = () => {
    setOpen(false);
  };

  const handleAddUser = (newUser: NewUser) => {
    const { name, email, password } = newUser;
    addUser({ name, email, password });
  };

  return (
    <React.Fragment>
      <SuccessErrorToast
        successOpen={successOpen}
        errorOpen={errorOpen}
        successMessage="Käyttäjä lisätty onnistuneesti!"
        errormessage="Käyttäjän lisääminen epäonnistui!"
        setSuccessOpen={setSuccessOpen}
        setErrorOpen={setErrorOpen}
      />
      <SuccessErrorToast
        successOpen={successDeleteOpen}
        errorOpen={errorDeleteOpen}
        successMessage="Käyttäjä poistettu onnistuneesti!"
        errormessage="Käyttäjän poistaminen epäonnistui!"
        setSuccessOpen={setSuccessDeleteOpen}
        setErrorOpen={setErrorDeleteOpen}
      />

      <AddUserForm
        open={open}
        handleAddClose={handleAddClose}
        handleAddUser={handleAddUser}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Käyttäjät</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Sähköposti</TableCell>
                <TableCell>Käyttöoikeudet</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map(user => (
                  <UserRow user={user} key={user.id} deleteUser={deleteUser} />
                ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" endIcon={<Add />} onClick={handleAddOpen}>
            Lisää
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
