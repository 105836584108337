import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export const SaveMeasurementResults = ({
  open,
  close,
  save,
  movement,
  leg,
  setMovement,
  setLeg
}: {
  open: boolean;
  close: () => void;
  save: () => void;
  movement: string;
  leg: string;
  setMovement: (val: string) => void;
  setLeg: (val: string) => void;
}) => {
  const handleMovementChange = (val: string) => {
    setMovement(val);
    if (val !== "Ontuva") {
      setLeg("");
    } else {
      setLeg("Oikea etujalka");
    }
  };

  const renderWhichLeg = () => {
    if (movement !== "Ontuva") {
      return <div />;
    } else {
      return (
        <TextField
          value={leg}
          onChange={e => {
            setLeg(e.target.value);
          }}
          select
          label="Jalka"
          style={{ margin: 10 }}
        >
          <MenuItem key={1} value="Oikea etujalka">
            Oikea etujalka
          </MenuItem>
          <MenuItem key={2} value="Vasen etujalka">
            Vasen etujalka
          </MenuItem>
          <MenuItem key={3} value="Oikea takajalka">
            Oikea takajalka
          </MenuItem>
          <MenuItem key={4} value="Vasen takajalka">
            Vasen takajalka
          </MenuItem>
        </TextField>
      );
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Tallenna mittaustulos</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              value={movement}
              onChange={e => {
                handleMovementChange(e.target.value);
              }}
              select
              label="Liike"
              style={{ margin: 10 }}
            >
              <MenuItem key={1} value="Puhdas">
                Puhdas
              </MenuItem>
              <MenuItem key={2} value="Epäselvä">
                Epäselvä
              </MenuItem>
              <MenuItem key={3} value="Ontuva">
                Ontuva
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {renderWhichLeg()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={save}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};
