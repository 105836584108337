import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useState } from "react";

import { Animal } from "../../store/apis/customersApi";

import ConfirmationDialog from "../utils/ConfirmationAlert";
import EditIcon from "@mui/icons-material/Edit";
export const AnimalRow = ({
  animal,
  handleDelete,
  setSelectedAnimal
}: {
  animal: Animal;
  handleDelete: (animal: Animal) => void;
  setSelectedAnimal: (animal: Animal) => void;
}) => {
  const [open, setOpen] = useState(false);

  if (!animal) {
    return <div />;
  } else {
    const DoB = new Date(animal.DoB);
    return (
      <>
        <ConfirmationDialog
          title={"Varoitus"}
          body={`Haluatko varmasti poistaa eläimen ${animal.name}?`}
          cb={() => handleDelete(animal)}
          okButtonText={"Poista"}
          handleClose={() => setOpen(false)}
          open={open}
        />
        <TableRow key={animal.id} hover={true}>
          <TableCell>{animal.name}</TableCell>
          <TableCell>{animal.species}</TableCell>
          <TableCell>{animal.race}</TableCell>
          <TableCell>
            {animal.DoB && DoB.getDay()}.{DoB.getMonth() + 1}.
            {DoB.getFullYear()}
          </TableCell>
          <TableCell align="right" width={20}>
            <EditIcon
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedAnimal(animal)}
            />
          </TableCell>
          <TableCell align="right" width={20}>
            <DeleteForeverOutlinedIcon
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }
};
