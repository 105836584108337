import * as React from "react";
import Grid from "@mui/material/Grid";
import remoteGATTServerManager from "../utils/RemoteGATTServerManager";
import { useEffect, useState } from "react";
import { useLoadAllSensorsQuery, selectConnectedDevices } from "../../store";
import { useAppSelector } from "../../hooks/hooks";
import { NewSensors } from "./NewSensors";
import { SavedSensors } from "./SavedSensors";
import { ScanDevicesButton } from "../utils/ScanDevicesButton";

export default function Sensors() {
  const { data } = useLoadAllSensorsQuery(null);
  const [GATTServers, setGATTServers] = useState<BluetoothRemoteGATTServer[]>();

  const connectedDevices = useAppSelector(selectConnectedDevices);

  useEffect(() => {
    setGATTServers(remoteGATTServerManager.remoteGATTServers);
  }, [connectedDevices]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SavedSensors
            savedSensors={data || []}
            GATTServers={GATTServers || []}
          />
          <br />
          <NewSensors
            GATTServers={GATTServers || []}
            savedSensors={data || []}
          />
        </Grid>
        <Grid item xs={3}>
          <ScanDevicesButton setGATTServers={setGATTServers} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
