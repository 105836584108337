//export const API_BASE_URL = "http://localhost:4000";
export const API_BASE_URL = "https://dogdevelop-api.smartintegration.fi";

export const nordicUartService: string = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
export const nordicUartTxCharacteristic: string =
  "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
export const nordicUartRxCharacteristic: string =
  "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
export const sensorNameService: string = "524daaa8-553d-2c87-cd40-1bb86644ff44";
export const sensorNameCharacteristic: string =
  "534daaa8-553d-2c87-cd40-1bb86644ff44";

export const sensorStateService: string =
  "524daaa8-603d-2c87-cd40-1bb866fcc144";
export const sensorStateCharacteristic: string =
  "534daaa8-603d-2c87-cd40-1bb866fcc144";

export const sensorMeasurementLengthService: string =
  "524daaa8-553d-2c87-cd40-1bb86644cc55";
export const sensorMeasurementLengthCharacteristic: string =
  "534daaa8-553d-2c87-cd40-1bb86644cc55";

export const characteristicvaluechanged: string = "characteristicvaluechanged";
export const gattserverdisconnected: string = "gattserverdisconnected";
export const startChar = new TextEncoder().encode("c");
export const stopChar = new TextEncoder().encode("x");
export const downloadChar = new TextEncoder().encode("t");

export const EOFString = "EOF";

export const BT_STATE_NOT_MEASURING = 0;
export const BT_STATE_MEASURING = 1;
export const BT_STATE_SENDING_DATA = 2;
