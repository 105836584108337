import * as React from "react";
import { RawData } from "../utils/RemoteGATTServerManager";
import { RenderGraph } from "./RenderGraph";
import { JSX } from "react/jsx-runtime";

export const RenderGraphs = ({ data }: { data?: RawData[] }) => {
  if (!data) return <div />;

  interface dataByDevice {
    [key: string]: string[];
  }

  const sortedData: dataByDevice = {};

  data.forEach(val => {
    if (!sortedData[val.id]) sortedData[val.id] = [];
    sortedData[val.id].push(val.value);
  });

  const keys = Object.keys(sortedData);

  let graphs: JSX.Element[] = [];

  if (keys.length) {
    keys.forEach(key => {
      graphs.push(<RenderGraph key={key} id={key} data={sortedData[key]} />);
    });
  }

  return <div>{graphs}</div>;

  //  return <RenderGraph id={keys[0]} data={sortedData[keys[0]]} />;
};
