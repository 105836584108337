import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";
import { API_BASE_URL } from "../../constants";

export interface User {
  id: number;
  name: string;
  email: string;
}

export interface NewUser {
  email: string;
  name: string;
  password: string;
}

const usersApi = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/auth`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
  }),
  tagTypes: ["loadUsers"],
  endpoints(builder) {
    return {
      loadAllUsers: builder.query<User[], null>({
        providesTags: users => ["loadUsers"],
        query: () => {
          return {
            url: "/loadAllUsers",
            method: "GET"
          };
        }
      }),
      deleteUser: builder.mutation<User, User>({
        invalidatesTags: ["loadUsers"],
        query: user => {
          return {
            url: `/${user.id}`,
            method: "DELETE"
          };
        }
      }),
      addUser: builder.mutation<User, NewUser>({
        invalidatesTags: ["loadUsers"],
        query: newUser => {
          return {
            url: "/addUser",
            method: "POST",
            body: {
              email: newUser.email,
              name: newUser.name,
              password: newUser.password
            }
          };
        }
      })
    };
  }
});

export const {
  useLoadAllUsersQuery,
  useDeleteUserMutation,
  useAddUserMutation
} = usersApi;
export { usersApi };
