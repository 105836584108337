import * as React from "react";
import Stack from "@mui/material/Stack";
import {useAppSelector} from "../../hooks/hooks";
import {selectConnectedDevices, useLoadAllSensorsQuery} from "../../store";
import {ScanDevicesButton} from "../utils/ScanDevicesButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {RenderConnectedSensor} from "./RenderConnectedSensor";
import Grid from "@mui/material/Grid";

export const ConnectedSensors = ({downloading, singleDownloadCb}: ({
  downloading: boolean,
  singleDownloadCb: () => void
})) => {

  const sensors = useLoadAllSensorsQuery(null);
  const connectedDevices = useAppSelector(selectConnectedDevices);
  const connectedSensors = sensors.data
    ? connectedDevices.map(dev => {
      const sensor = sensors.data?.find(s => s.sensorId === dev.id);
      if (!sensor) return dev;
      return {...dev, humanName: sensor.humanName};
    })
    : [];
  connectedSensors.sort((a, b) => a.humanName! < b.humanName! ? -1 : 1);


  return (
    <>
      <Divider textAlign={"left"}>
        <Typography variant="h6" style={{fontSize: "0.8em"}}>
          Mittauksen sensorit
        </Typography>
      </Divider>
      <Grid container>
        <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
          {connectedSensors.map(sensor =>
            <RenderConnectedSensor
              sensor={sensor}
              key={sensor.id}
              singleDownloadCb={singleDownloadCb}
            />)}
        </Stack>
      </Grid>
      <Grid container direction={'row-reverse'}>
        <Grid item>
          <ScanDevicesButton setGATTServers={() => {
          }}/>
        </Grid>
      </Grid>
    </>
  );
};
