import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

import { Customer } from "../../store/apis/customersApi";

export const AddCustomerForm = ({
  open,
  handleAddClose,
  handleAddCustomer,
  selectedCustomer
}: {
  open: boolean;
  handleAddClose: () => void;
  handleAddCustomer: (newCustomer: Customer) => void;
  selectedCustomer: Customer | undefined;
}) => {
  const initErrors = {
    name: ""
  };

  const [name, setName] = useState("");
  const [errors, setErrors] = useState<Customer>(initErrors);

  useEffect(() => {
    if (selectedCustomer) {
      setName(selectedCustomer.name);
    }
  }, [selectedCustomer]);

  const close = () => {
    setErrors(initErrors);
    handleAddClose();
  };
  const addUser = () => {
    const err: Customer = { name: "" };
    let errors = false;
    if (name.length < 1) {
      errors = true;
      err.name = "Anna nimi";
    }
    if (!errors) {
      handleAddCustomer({ name, id: selectedCustomer?.id || undefined });
      close();
    } else {
      setErrors(err);
    }
  };

  useEffect(() => {
    if (!open) {
      setName("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        {selectedCustomer ? "Muokkaa asiakasta" : "Lisää asiakas"}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errors.name)}
          helperText={errors.name}
          autoFocus
          margin="dense"
          id="name"
          label="Nimi"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setName(event.target.value)}
          value={name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={addUser}>
          {selectedCustomer ? "Muokkaa" : "Lisää"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
