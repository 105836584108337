import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import ConfirmationDialog from "../utils/ConfirmationAlert";
import { Customer } from "../../store/apis/customersApi";
import { useNavigate } from "react-router-dom";
export const CustomerRow = ({
  customer,
  deleteCustomer,
  setSelectedCustomer
}: {
  customer: Customer;
  deleteCustomer: (customer: Customer) => void;
  setSelectedCustomer: (customer: Customer) => void;
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteCustomer(customer);
  };

  if (!customer) {
    return <div />;
  } else
    return (
      <>
        <ConfirmationDialog
          title={"Varoitus"}
          body={`Haluatko varmasti poistaa asiakkaan ${customer.name}?`}
          cb={handleDelete}
          okButtonText={"Poista"}
          handleClose={() => setOpen(false)}
          open={open}
        />
        <TableRow key={customer.id} hover={true}>
          <TableCell
            onClick={() => navigate(`/customer/${customer.id}`)}
            style={{ cursor: "pointer" }}
          >
            {customer.name}
          </TableCell>
          <TableCell align="right" width={20}>
            <EditIcon
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedCustomer(customer)}
            />
          </TableCell>
          <TableCell align="right" width={20}>
            <DeleteForeverOutlinedIcon
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableRow>
      </>
    );
};
