import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import SensorsIcon from "@mui/icons-material/Sensors";
import PersonIcon from "@mui/icons-material/Person";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { useLocation, useNavigate } from "react-router-dom";
import { List } from "@mui/material";

export const MenuList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <List component="nav">
      <ListItemButton
        selected={pathname === "/measurement"}
        onClick={() => navigate("/measurement")}
      >
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText primary="Mittaus" />
      </ListItemButton>
      <ListItemButton
        selected={pathname === "/measurementResults"}
        onClick={() => navigate("/measurementResults")}
      >
        <ListItemIcon>
          <SsidChartIcon />
        </ListItemIcon>
        <ListItemText primary="Mittaustulokset" />
      </ListItemButton>
      <ListItemButton
        selected={pathname.substring(0, 9) === "/customer" || pathname === "/"}
        onClick={() => navigate("/")}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Asiakkaat" />
      </ListItemButton>
      <ListItemButton
        selected={pathname === "/sensors"}
        onClick={() => navigate("/sensors")}
      >
        <ListItemIcon>
          <SensorsIcon />
        </ListItemIcon>
        <ListItemText primary="Sensorit" />
      </ListItemButton>
      <ListItemButton
        selected={pathname === "/users"}
        onClick={() => navigate("/users")}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Käyttäjät" />
      </ListItemButton>
    </List>
  );
};
